<template>
  <modal :show.sync="chooseViewModal" :showClose="false"
    modal-classes="modal-dialog-centered modal-xl choose-view-modal">

    <h5 slot="header" class="modal-title text-center" id="modal-title-default">
        Who are you?
    </h5>

    <div class="row row-grid">
      <div class="col-lg-6">
        <div class="info info-horizontal bg-yellow border-radius-16">
          <div class="row align-items-center text-left">
            <div class="col-lg-6">
              <h4 class="carrier-title">I am a Carrier</h4>
              <p class="carrier-description">
                I want to build tariffs, connect to my customers, and simplify quoting.
              </p>
              <base-button
                type="primary"
                class="carrier-btn"
                @click="chooseView(viewTypeList.carrier)"
              >
                View as Carrier
              </base-button>
            </div>
            <div class="col-lg-6 pr-0">
              <img class="width-100" src="img/landing-page/carrier.png">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="info info-horizontal bg-green border-radius-16">
          <div class="row align-items-center text-left">
            <div class="col-lg-6">
                <h4 class="shipper-title">I am a Shipper/Broker</h4>
                <p class="shipper-description">I want easy quotes from all my carriers and simplified quote management.</p>
                <base-button
                  type="primary"
                  class="shipper-btn"
                  @click="chooseView(viewTypeList.shipper)"
                >
                  View as Shipper/Broker
                </base-button>
            </div>
            <div class="col-lg-6  pr-0">
              <img class="width-100" src="img/landing-page/shipper.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import { viewType } from "@/helpers/constants.js";

export default {
  components: {
    Modal
  },
  data: function () {
    return {
      chooseViewModal: true,
      viewTypeList: viewType,
    }
  },
  methods: {
    chooseView(selectedViewType) {
      this.$emit("select-view-type", selectedViewType);
      this.chooseViewModal = false;
    }
  }
};
</script>
<style></style>
