<template>
  <div class="row text-left ml-0 mb-3">
    <div class="col-lg-6">
      <h1>
          Input Shipment details
      </h1>
      <p>
          Don't worry about rule tariff complexity or routing, Pallet handles it all. Simply input the origin, destination, and dimensions of your shipment for density rated freight or commodity for class based freight press get rates.
      </p>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Handles routing and accessorials
        <p>
          Go as wide or specific as you'd like creating custom origin and destination points. As large as a state or as small as a store.
        </p>
      </div>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Handles tariff rules and conditions
        <p>
          Use whatever pricing methods make sense for your business. Mix pricing methods from lane to lane to ensure you're always pricing most efficiently.
        </p>
      </div>
    </div>

    <div class="col-lg-6 text-center">
        <img class="width-100 step-img-box-shadow" src="img/landing-page/steps/step-1-2.png" />
    </div>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
