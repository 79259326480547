/**
 * Object containing URLs for different views of the application.
 */
export const appUrls = {
  pallet: "https://app.usepallet.com/#/",
  tariff: "https://tariff-app.usepallet.com/#/",
};

/**
 * Object defining different view types.
 */
export const viewType = {
    carrier: "Carrier",
    shipper: "Shipper",
};

/**
 * Strings specific to the carrier view.
 */
export const carrierStrings = {
  viewAsButtonText: "View as Shipper",
  reviewText: "4.7 (200+ reviews)",
  noOfUsers: "38,182",
  noOfHappyUsers: "38K",
  growingRating: "4.7",

  tariffCreationAndManagement: "Let the complexity of your tariff be your advantage.",

  // Feature section strings
  feature1Title: "Drag and Drop Tariff Builder",
  feature1Subtitle: "Build, update and manage complex tariffs in seconds.",
  feature2Title: "Dynamic Pricing",
  feature2Subtitle: "Create pricing for the current conditions of your business.",
  feature3Title: "Tariff Audit and Versioning",
  feature3Subtitle: "Create historical versions of your tariff so customer audits are a breeze.",
  feature4Title: "Turnkey API",
  feature4Subtitle: "With one click your customers will be integrated and can pull quotes immediately.",

  // How it works section strings
  howItWorksTitle: "How it works",
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",
  step4: "Step 4",
  // Step 1 strings
  step1Title: "Create the sections of your tariff.",
  step1Subtitle: "Build the lanes, accessorials, fuel surcharges, rules, restrictions and conditions that make up your business.",
  step1CustomRouting: "Custom Routing",
  step1CustomRoutingDescription: "Go as wide or specific as you'd like creating custom origin and destination points. As large as a state or as small as a store.",
  step1FlexiblePricingStructures: "Flexible Pricing structures",
  step1FlexiblePricingStructuresDescription: "Use whatever pricing methods make sense for your business. Mix pricing methods from lane to lane to ensure you're always pricing most efficiently.",

  // Step 2 strings
  step2Title: "Create your customers and customer groups",
  step2Subtitle: "Invite all your customers and add any customers who use the same tariff into a group for simple tariff management. ",
  step2CustomRouting: "One click integration",
  step2CustomRoutingDescription: "Send your customers a rating tool invitation and with a single click they'll be able to start quoting using their specific contract pricing. No lengthy integration and onboarding.",

  // Instant Pricing section strings
  instantPricingTitle: "Instant Pricing for your clients",
  instantPricingSubtitle: "Clients can access instant pricing via API, eliminating the need for manual quoting and reducing operational overhead.",

  // Business section strings
  growYourBusiness :"GROW YOUR BUSINESS",
  businessTitle :"Build a rules engine that will drive your business forward.",
  businessSubtitle :"Massivly reduce the need for repricing because of mistakes made quoting from your rules tariff.",
  businessDescription1: "Manage and update tariffs simply and seamlessly.",
  businessDescription2: "Quote more.",
  businessDescription3: "Quote more accurately.",
  businessDescription4: "Consistent pricing, auditable, transparent.",
};

/**
 * Strings specific to the shipper view.
 */
export const shipperStrings = {
  viewAsButtonText: "View as Carrier",
  reviewText: "4.7 (200+ reviews)",
  noOfUsers: "37,185",
  noOfHappyUsers: "37K",
  growingRating: "4.5",
  tariffCreationAndManagement: "Let the complexity of your tariff be your advantage.",

  // Feature section strings
  feature1Title: "Drag and Drop Tariff Builder",
  feature1Subtitle: "Build, update and manage complex tariffs in seconds.",
  feature2Title: "Dynamic Pricing",
  feature2Subtitle: "Create pricing for the current conditions of your business.",
  feature3Title: "Tariff Audit and Versioning",
  feature3Subtitle: "Create historical versions of your tariff so customer audits are a breeze.",
  feature4Title: "Turnkey API",
  feature4Subtitle: "With one click your customers will be integrated and can pull quotes immediately.",

  // How it works section strings
  howItWorksTitle: "How it works",
  step1: "Step 1",
  step2: "Step 2",
  step3: "Step 3",
  step4: "Step 4",
  // Step 1 strings
  step1Title: "Create the sections of your tariff.",
  step1Subtitle: "Build the lanes, accessorials, fuel surcharges, rules, restrictions and conditions that make up your business.",
  step1CustomRouting: "Custom Routing",
  step1CustomRoutingDescription: "Go as wide or specific as you'd like creating custom origin and destination points. As large as a state or as small as a store.",
  step1FlexiblePricingStructures: "Flexible Pricing structures",
  step1FlexiblePricingStructuresDescription: "Use whatever pricing methods make sense for your business. Mix pricing methods from lane to lane to ensure you're always pricing most efficiently.",

  // Step 2 strings
  step2Title: "Step 2 title here",
  step2Subtitle: "Step 2 sub title here",
  step2CustomRouting: "Lorem ipsum dolor sit amet",
  step2CustomRoutingDescription: "Lorem ipsum dolor sit amet consectetur. Arcu blandit donec lacus elementum tempor donec enim amet ac.",
  step2FlexiblePricingStructures: "Lorem ipsum dolor sit amet",
  step2FlexiblePricingStructuresDescription: "Lorem ipsum dolor sit amet consectetur. Vulputate rhoncus varius vulputate donec. Consectetur cursus.",

  // Instant Pricing section strings
  instantPricingTitle: "Instant Pricing for your clients",
  instantPricingSubtitle: "Clients can access instant pricing via API, eliminating the need for manual quoting and reducing operational overhead.",

  // Business section strings
  growYourBusiness :"GROW YOUR BUSINESS",
  businessTitle :"Build a rules engine that will drive your business forward.",
  businessSubtitle :"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt.",
  businessDescription1: "Manage and update tariffs simply and seamlessly.",
  businessDescription2: "Quote more.",
  businessDescription3: "Quote more accurately.",
  businessDescription4: "Consistent pricing, auditable, transparent.",
};
