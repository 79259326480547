<template>
  <div>
    <header class="header-5">
      <div class="page-header">
        <div class="landing-page-container">
          <div class="row align-items-center text-left pl30-mlauto">
            <div class="col-lg-5 col-10">
              <h1 class="display-3 mt-3">
                No more quoting headaches
              </h1>
              <p class="lead pb-4">
                  All your carriers in the same place. Contract and public pricing. Enter shipment details once and rates from all your carriers are returned.
              </p>

              <div class="row row-input ml-0">
                <div class="col-sm-4 col-12 pl-lg-0">
                  <a href="https://calendly.com/tate-wplh/30min" target="_blank">
                    <base-button
                      type="primary"
                      class="btn-block landing-page-btn-green"
                    >
                      Book Demo
                      <img
                        class="ml-lg-1"
                        src="img/landing-page/arrow-icon.png"
                      />
                    </base-button>
                  </a>
                </div>
                <div class="col-sm-6 col-12 pl-lg-0">
                  <a href="https://app.usepallet.com">
                    <base-button
                      type="primary"
                      class="btn-block landing-page-btn-white"
                    >
                      <img
                        class="ml-lg-1"
                        src="img/landing-page/play-icon.png"
                      />
                      Get started for free
                    </base-button>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-12 pl-0">
              <img
                src="img/landing-page/forklift.png"
                class="landing-img"
              />
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="rating-view">
      <div class="row align-items-center text-left pl30-mlauto">
        <div class="col-10">
          <h1 class="white-text font-48">
              Instant rates from all your carriers
          </h1>
        </div>
      </div>
    </div>

    <div class="row padding-container pb-50">
      <div class="col-md-3 mb-3">
        <div class="info feature-info">
          <div
            class="icon icon-lg icon-shape info-icon-success shadow rounded-circle"
          >
            <i class="fa fa-star-o"></i>
          </div>
          <h6 class="info-title  text-primary">
              Ease
          </h6>
          <p class="description opacity-8">
              Local, regional, or national pallet can get quotes from all your carriers in one place.
          </p>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <div class="info feature-info">
          <div
            class="icon icon-lg icon-shape info-icon-success shadow rounded-circle"
          >
            <i class="fa fa-star-o"></i>
          </div>
          <h6 class="info-title  text-primary">
              Speed
          </h6>
          <p class="description opacity-8">
              Single click integration. Add carriers using pallet with a single click and you'll be quoting using your rates instantly.
          </p>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <div class="info feature-info">
          <div
            class="icon icon-lg icon-shape info-icon-success shadow rounded-circle"
          >
            <i class="fa fa-star-o"></i>
          </div>
          <h6 class="info-title  text-primary">
              Simplicity
          </h6>
          <p class="description opacity-8">
              Touchless updates. Integrated carriers require no management or maintenance.
          </p>
        </div>
      </div>
      <div class="col-md-3 mb-3">
        <div class="info feature-info">
          <div
            class="icon icon-lg icon-shape info-icon-success shadow rounded-circle"
          >
            <i class="fa fa-star-o"></i>
          </div>
          <h6 class="info-title  text-primary">
              Accuracy and Efficiency
          </h6>
          <p class="description opacity-8">
              Your freight is accurately rated using Pallet integrated carriers, and your time is protected as you aren't starting from scratch every time you search for a carrier, negotiate a price and seek to undertsand their lane coverage.          </p>
        </div>
      </div>
    </div>

    <div class="work-container">
      <div class="font-43 text-center pt-4">How it works</div>
      <div class="row padding-container">
        <tabs pills fill type="info" class="pl-15 work-container-tab">
          <tab-pane>
            <template slot="label">
              <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.163 0.941238C12.0258 0.84909 11.8648 0.799927 11.7 0.799927C11.5352 0.799927 11.3742 0.84909 11.237 0.941238C8.08158 3.08888 4.48464 4.48573 0.717043 5.02661C0.517976 5.05544 0.335883 5.15578 0.204174 5.30921C0.0724648 5.46264 -2.6808e-05 5.65887 7.43683e-09 5.86191V13.4555C7.43683e-09 20.0215 3.85599 24.8122 11.3991 27.7434C11.5928 27.8188 11.8072 27.8188 12.0009 27.7434C19.544 24.8122 23.4 20.0198 23.4 13.4555V5.86191C23.4 5.65862 23.3272 5.46218 23.1952 5.30871C23.0631 5.15524 22.8807 5.05507 22.6813 5.02661C18.9143 4.48548 15.3179 3.08864 12.163 0.941238ZM18.1417 10.678L11.456 17.4278C11.3783 17.5064 11.2861 17.5688 11.1846 17.6113C11.0831 17.6538 10.9742 17.6757 10.8643 17.6757C10.7544 17.6757 10.6455 17.6538 10.544 17.6113C10.4425 17.5688 10.3502 17.5064 10.2726 17.4278L6.92974 14.0529C6.77282 13.8945 6.68466 13.6796 6.68466 13.4555C6.68466 13.2315 6.77282 13.0166 6.92974 12.8582C7.08667 12.6997 7.2995 12.6107 7.52143 12.6107C7.74335 12.6107 7.95619 12.6997 8.11311 12.8582L10.8643 15.6374L16.9583 9.48322C17.036 9.40477 17.1283 9.34255 17.2298 9.30009C17.3313 9.25764 17.4401 9.23578 17.55 9.23578C17.6599 9.23578 17.7687 9.25764 17.8702 9.30009C17.9717 9.34255 18.064 9.40477 18.1417 9.48322C18.2194 9.56167 18.281 9.6548 18.3231 9.75729C18.3651 9.85979 18.3868 9.96965 18.3868 10.0806C18.3868 10.1915 18.3651 10.3014 18.3231 10.4039C18.281 10.5064 18.2194 10.5995 18.1417 10.678Z"
                  fill="#083C2F"
                />
              </svg>
              &nbsp;&nbsp; Step 1
            </template>

            <step1-tab-content/>

          </tab-pane>
          <tab-pane :active="false">
            <template slot="label">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.7002 21.7999H9.7002V14.2999H6.7002V21.7999ZM18.7002 21.7999H21.7002V6.79993H18.7002V21.7999ZM12.7002 21.7999H15.7002V17.2999H12.7002V21.7999ZM12.7002 14.2999H15.7002V11.2999H12.7002V14.2999ZM3.7002 27.7999C2.8752 27.7999 2.1687 27.5059 1.5807 26.9179C0.992698 26.3299 0.699198 25.6239 0.700198 24.7999V3.79993C0.700198 2.97493 0.994198 2.26843 1.5822 1.68043C2.1702 1.09243 2.8762 0.798929 3.7002 0.799929H24.7002C25.5252 0.799929 26.2317 1.09393 26.8197 1.68193C27.4077 2.26993 27.7012 2.97593 27.7002 3.79993V24.7999C27.7002 25.6249 27.4062 26.3314 26.8182 26.9194C26.2302 27.5074 25.5242 27.8009 24.7002 27.7999H3.7002Z"
                  fill="#B9C1BF"
                />
              </svg>
              &nbsp;&nbsp; Step 2
            </template>

            <step2-tab-content/>

          </tab-pane>
          <tab-pane>
            <template slot="label">
              <svg
                width="32"
                height="28"
                viewBox="0 0 32 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6136 15.9874C19.8068 15.9874 20.821 15.4952 21.6562 14.5109C22.4915 13.5265 22.9091 12.3312 22.9091 10.9249C22.9091 9.51868 22.4915 8.32336 21.6562 7.33899C20.821 6.35462 19.8068 5.86243 18.6136 5.86243C17.4205 5.86243 16.4062 6.35462 15.571 7.33899C14.7358 8.32336 14.3182 9.51868 14.3182 10.9249C14.3182 12.3312 14.7358 13.5265 15.571 14.5109C16.4062 15.4952 17.4205 15.9874 18.6136 15.9874ZM8.59091 21.0499C7.80341 21.0499 7.12902 20.7192 6.56775 20.0577C6.00648 19.3962 5.72632 18.6019 5.72727 17.6749V4.17493C5.72727 3.2468 6.00791 2.45199 6.56918 1.79049C7.13046 1.12899 7.80436 0.798805 8.59091 0.79993H28.6364C29.4239 0.79993 30.0982 1.13068 30.6595 1.79218C31.2208 2.45368 31.501 3.24793 31.5 4.17493V17.6749C31.5 18.603 31.2194 19.3979 30.6581 20.0594C30.0968 20.7209 29.4229 21.051 28.6364 21.0499H8.59091ZM2.86364 27.7999C2.07614 27.7999 1.40175 27.4692 0.840479 26.8077C0.279207 26.1462 -0.000952114 25.3519 2.43093e-06 24.4249V7.54993C2.43093e-06 7.0718 0.137457 6.67074 0.412366 6.34674C0.687275 6.02274 1.02709 5.8613 1.43182 5.86243C1.8375 5.86243 2.1778 6.02443 2.45271 6.34843C2.72762 6.67243 2.86459 7.07293 2.86364 7.54993V24.4249H25.7727C26.1784 24.4249 26.5187 24.5869 26.7936 24.9109C27.0685 25.2349 27.2055 25.6354 27.2045 26.1124C27.2045 26.5905 27.0671 26.9916 26.7922 27.3156C26.5173 27.6396 26.1775 27.801 25.7727 27.7999H2.86364ZM8.59091 7.54993C9.37841 7.54993 10.0528 7.21918 10.6141 6.55768C11.1753 5.89618 11.4555 5.10193 11.4545 4.17493H8.59091V7.54993ZM28.6364 7.54993V4.17493H25.7727C25.7727 5.10305 26.0534 5.89787 26.6146 6.55937C27.1759 7.22086 27.8498 7.55105 28.6364 7.54993ZM8.59091 17.6749H11.4545C11.4545 16.7468 11.1739 15.952 10.6126 15.2905C10.0514 14.629 9.37746 14.2988 8.59091 14.2999V17.6749ZM25.7727 17.6749H28.6364V14.2999C27.8489 14.2999 27.1745 14.6307 26.6132 15.2922C26.0519 15.9537 25.7718 16.7479 25.7727 17.6749Z"
                  fill="#B9C1BF"
                />
              </svg>
              &nbsp;&nbsp; Step 3
            </template>

            <step3-tab-content
            />

          </tab-pane>
        </tabs>
      </div>
    </div>

    <div class="row align-items-center text-left support-container">
      <div class="col-lg-6">
        <img class="instant-pricing-img" src="img/landing-page/instant-pricing.png" />
      </div>
      <div class="col-lg-6">
        <h1 class="font-display-1">
            Get started right away for free.
        </h1>
        <p>
            Sign up is free. Add all your carriers that are already a part of pallet with a single click. Send invites to carriers you want that are not yet on Pallet
        </p>
          <p>
              Want to add a digital tariff even for a carrier who isn't on pallet? You can digitize those tariffs on your own with Pallet's Shipper/Broker managed tariff feature          </p>
      </div>
    </div>

    <div class="why--choose-view text-center background-with-image">
      <h1 class="display-3 mt-3 why-choose-text">
        Why you
        <label class="choose-text display-3">choose</label>
        us
      </h1>
      <p class="why-choose-text-p mt-3 mb-5">
        Grow and compete as the industry moves into the future. Focus on what
        you do best and let us provide you with the tools that will allow you
        to service clients with the sophistication of a major carrier.
      </p>
    </div>

    <div class="padding-container pb-50">
      <div class="partner-container pb-3">
        <div class="row align-items-center text-left partner-box">
          <div class="col-md-1"></div>
          <div class="col-md-6">
            <h1 class="trusted-growing-text pt-3 pb-3">
              Do you think pallet would be a good integration partner with
              your platform? Reach out and let's help move this industry into
              the future together.
            </h1>
          </div>
          <div class="col-md-4 text-right">
              <a href="https://forms.gle/an1MR8HN4euJGKMA6" target="_blank">
                  <base-button type="primary" class="partner-btn">
                      Become A Pallet Partner
                  </base-button>
              </a>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row align-items-center text-left">
          <div class="col-md-1"></div>
          <div class="col-md-10">
            <p class="parter-line"></p>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>
<script>
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import Step1TabContent from "@/views/shipper-broker-page/components/Step1TabContent.vue";
import Step2TabContent from "@/views/shipper-broker-page/components/Step2TabContent.vue";
import Step3TabContent from "@/views/shipper-broker-page/components/Step3TabContent.vue";
import AppFooter from "@/layout/AppFooter.vue";

export default {
  bodyClass: "about-us",
  components: {
    Tabs,
    TabPane,
    Step1TabContent,
    Step2TabContent,
    Step3TabContent,
    AppFooter,
  },
  data: function() {
    return {

    };
  },
  methods: {

  }
};
</script>
<style></style>
