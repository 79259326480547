<template>
  <div class="row text-left ml-0">
    <div class="col-lg-6">
      <h1>
          Sync your customers and your pricing
      </h1>
      <p>
          Link the customers or customer groups you want to a specific tariff. When tariff maintenance is this easy you can really build custom tariffs that make sure every customer is profitable.
      </p>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          One click customer updates
        <p>
            Press sync and tariff updates are instantly available to all customers associated with that tariff.
        </p>
      </div>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Tariff versioning
        <p>
            Use versioning to take historical snap shots of your tariff at a given time so you can always return to audit your rates as they were should a customer request it.
        </p>
      </div>
    </div>
    
    <div class="col-lg-6 text-center">
      <img class="width-60" src="img/landing-page/work-logo.png" />
    </div>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
