<template>
  <footer class="footer footer-big bg-gradient-default">
    <div class="">
      <div class="row">
        <div class="col-md-7 col-4">
          <img class="footer-app-logo" src="img/landing-page/pallet-logo.svg" alt="logo" />
        </div>
        <div class="col-md-5 col-8 text-right">
          <a href="https://tariff-app.usepallet.com">
            <base-button type="primary" class="landing-page-btn-green">
              Get started for free
            </base-button>
          </a>
        </div>
      </div>
      <hr class="footer-hr" />
<!--      <div class="content">-->
<!--        <div class="row">-->
<!--          <div class="col-md-4 col-6">-->
<!--            <div class="column">-->
<!--              <h4 class="mb-4">About Us</h4>-->
<!--              <p>-->
<!--                Lorem ipsum dolor sit amet, consectetur-->
<!--                adipiscing elit. Nam mauris risus, lobortis a-->
<!--                commodo at, varius sit amet ipsum.-->
<!--              </p>-->
<!--              <p>T. (00) 987 65432</p>-->
<!--              <p>E. hello@pallet.com</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-3 col-6">-->
<!--            <div class="column">-->
<!--              <h4 class="mb-4">Services</h4>-->
<!--              <p>Service 01</p>-->
<!--              <p>Service 02</p>-->
<!--              <p>Service 03</p>-->
<!--              <p>Service 04</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-3 col-6">-->
<!--            <div class="column">-->
<!--              <h4 class="mb-4">About</h4>-->
<!--              <p>Our Story</p>-->
<!--              <p>Benefits</p>-->
<!--              <p>Team</p>-->
<!--              <p>Careers</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-md-2 col-6">-->
<!--            <div class="column">-->
<!--              <h4 class="mb-4">Help</h4>-->
<!--              <p>FAQs</p>-->
<!--              <p>Contact Us</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",

};
</script>
<style></style>
