<template>
  <div class="row text-left ml-0">
    <div class="col-lg-6">
      <h1>
          Save quotes and send to your TMS      </h1>
      <p>
          Drag and drop the components you want to build a specific tariff from the lists of lanes, accessorials, fuel surcharges, rules, restrictions and conditions you created
      </p>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Integrates anywhere
        <p>
            Connect pallet to your TMS or any other application so your regular workflow can remain in one place        </p>
      </div>
        <div>
            <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
            Quote history
            <p>
                Save quotes so you can get new pricing for regular shipments with a single click         </p>
        </div>
    </div>

    <div class="col-lg-6 text-center">
      <img class="width-60" src="img/landing-page/work-logo.png" />
    </div>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
