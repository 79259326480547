<template>
    <div class="row text-left ml-0">
        <div class="col-lg-6">
            <h1>
                Create your customers and customer groups
            </h1>
            <p>
                Invite all your customers and add any customers who use the same tariff into a group for simple tariff management.
            </p>
            <div>
                <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
                One click integration
                <p>
                    Send your customers a rating tool invitation and with a single click they'll be able to start quoting using their specific contract pricing. No lengthy integration and onboarding.
                </p>
            </div>
        </div>
        <div class="col-lg-6 text-center">
            <img class="width-60 step-img-box-shadow" src="img/landing-page/steps/step-2-1.png" />
        </div>
    </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
