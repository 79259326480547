<template>
  <div>
    <div class="wrapper">
      <app-header
        :viewAsButtonText="viewAsButtonText"
        :loginUrl="loginUrl"
        @change-view="changeView"
      />
      
      <carrier-page
        v-if="selectedViewType == viewType.carrier"
      />

      <shipper-broker-page
        v-if="selectedViewType == viewType.shipper"
      />
      
    </div>
    <choose-view-modal @select-view-type="selectViewType" />
  </div>
</template>
<script>
import AppHeader from "@/layout/AppHeader.vue";
import CarrierPage from "@/views/carrier-page/CarrierPage.vue";
import ShipperBrokerPage from "@/views/shipper-broker-page/ShipperBrokerPage.vue";
import ChooseViewModal from "@/views/ChooseViewModal.vue";

import {
  viewType,
  appUrls,
} from "@/helpers/constants.js";

export default {
  bodyClass: "about-us",
  components: {
    AppHeader,
    CarrierPage,
    ShipperBrokerPage,
    ChooseViewModal
  },
  data: function() {
    return {
      viewAsButtonText: "View as Shipper",
      viewType: viewType,
      selectedViewType: viewType.carrier,
      loginUrl: appUrls.pallet,
    };
  },
  methods: {
    /**
     * Selects the view type based on the provided type.
     * Updates strings and login URL accordingly.
     *
     * @param {string} type - The type of view (Carrier or Shipper).
     */
    selectViewType(type) {
      if (viewType.carrier === type) {
        this.viewAsButtonText = "View as Shipper";
        this.loginUrl = appUrls.tariff;
      } else {
        this.viewAsButtonText = "View as Carrier";
        this.loginUrl = appUrls.pallet;
      }
      
      this.selectedViewType = type;
    },
    /**
     * Changes the current view type.
     * If the current view is Carrier, changes it to Shipper, and vice versa.
     */
    changeView() {
      let type =
        this.selectedViewType == viewType.carrier
          ? viewType.shipper
          : viewType.carrier;
      this.selectViewType(type);
    }
  }
};
</script>
<style></style>
