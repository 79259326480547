<template>
  <header class="header-global">
    <base-nav
      class="navbar-main headroom landing-page"
      id="navbar-main"
      type=""
      effect="dark"
      expand
    >
      <router-link slot="brand" class="mr-lg-5" to="/">
        <img class="pallet-logo" src="img/landing-page/pallet-logo.svg" alt="logo" />
      </router-link>

      <el-dropdown trigger="click" class="mv-menu">
        <i class="fa fa-bars" aria-hidden="true"></i>
        
        <el-dropdown-menu slot="dropdown" class="mv-el-popper">
          <div>
            <a
              :href="loginUrl"
              class="btn login-btn"
            >
              Sign in
            </a>
          </div>
          <div>
            <a
              class="btn login-btn"
              @click="changeView()"
            >
              {{viewAsButtonText}}
            </a>
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto dv-button">
        <a
          :href="loginUrl"
          class="btn login-btn"
        >
          Sign in
        </a>
        <base-button
          type="primary"
          class="landing-page-btn-green"
          @click="changeView()"
        >
          {{viewAsButtonText}}
        </base-button>
      </ul>
    </base-nav>
  </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import Headroom from "headroom.js";
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

export default {
  components: {
    BaseNav,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    viewAsButtonText: String,
    loginUrl: String,
  },
  methods: {
    changeView() {
      this.$emit("change-view");

      let mvElPopperElement = document.querySelector('.mv-el-popper');
      if (mvElPopperElement) {
        mvElPopperElement.style.display = "none";
      }
    }
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
  }
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}
</style>
