import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
Vue.use(Router);

export const routesList = [
  {
    path: "/",
    name: "home",
    components: {
      default: Home,
    }
  },
];

const router = new Router({
  routes: routesList,
  linkActiveClass: "active",
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  next();
  router.push({ path: '/', name: "home" }).catch(() => {});
});

export default router;
