<template>
  <div class="row text-left ml-0">
    <div class="col-lg-6">
      <h1>
        Build your tariff
      </h1>
      <p>
          Drag and drop the components you want to build a specific tariff from the lists of lanes, accessorials, fuel surcharges, rules, restrictions and conditions you created
      </p>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Maximum flexibilty
        <p>
            Update, expire, and create versions of tariffs at your convenience. Changes will be instantly reflected for your customers in Pallet's free rating tool, or across any their integrated digital applications.
        </p>
      </div>
    </div>

    <div class="col-lg-6 text-center">
      <img class="width-100 step-img-box-shadow" src="img/landing-page/steps/step-3-1.png" />
    </div>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
