<template>
  <div class="row text-left ml-0 mb-3">
    <div class="col-lg-6">
      <h1>
        Create the sections of your tariff.
      </h1>
      <p>
        Build the lanes, accessorials, fuel surcharges, rules, restrictions and conditions that make up your business.
      </p>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Custom Routing
        <p>
          Go as wide or specific as you'd like creating custom origin and destination points. As large as a state or as small as a store.
        </p>
      </div>
      <div>
        <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
          Flexible Pricing structures
        <p>
          Use whatever pricing methods make sense for your business. Mix pricing methods from lane to lane to ensure you're always pricing most efficiently.
        </p>
      </div>
    </div>
    
    <div class="col-lg-6 text-center">
        <img class="width-60 step-img-box-shadow" src="img/landing-page/steps/step-1-1.png" />
    </div>
  </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
