<template>
    <div class="row text-left ml-0">
        <div class="col-lg-6">
            <h1>
                Review all rates
            </h1>
            <p>
                All the information you need in one place
            </p>
            <div>
                <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
                Aggregated Rates
                <p>
                    No more going from carrier site to carrier site for pricing, or looking up pricing on PDF tariffs. Everything you need in one place, one one page.                 </p>
            </div>
            <div>
                <img src="img/landing-page/tracking.svg" alt="" /> &nbsp;&nbsp;
                Detailed Quotes
                <p>Breakdowns for every quote so you know exactly what all the charges are                </p>
            </div>
        </div>
        <div class="col-lg-6 text-center">
            <img class="width-100 step-img-box-shadow" src="img/landing-page/steps/step-2-2.png" />
        </div>
    </div>
</template>
<script>


export default {
  components: {

  },
  props: {
    strings: Object,
  },
};
</script>
<style></style>
